/*
Cathode Church colour scheme
*/
@use 'sass:color';

$bright-pink: #f75ae2;
$steel-pink: #d22abc;
$russian-violet: #42144f;
$lilac: #bf9bc8;
$lavender: #e8d9e5;
$princeton-orange: #ff8d41;

$bright-pink-deep: darken($bright-pink, 25%);
$russian-violet-trans: color.adjust($russian_violet, $alpha: -0.15);
$russian-violet-dark: darken($russian-violet, 8%);
$russian-violet-darker: darken($russian-violet, 12%);
$russian-violet-light: lighten($russian-violet, 17%);
$lilac-dark: darken($lilac, 3%);
$lilac-darker: darken($lilac, 12%);



// Dependent colors
$black: #000000;
$white: #ffffff; // White

// Tell UI to use selected colors
$ui-base-color: $russian-violet-dark !default;                  // Darkest		== Comes out to #0f0512
$ui-base-lighter-color: $russian-violet-light !default; // Lighter darkest
$ui-primary-color: $lilac !default;            // Lighter
$ui-secondary-color: lighten($princeton-orange, 13%) !default;        // Lightest
$ui-highlight-color: $steel-pink !default;

$ui-button-color: $white !default;
$ui-button-background-color: $bright-pink-deep !default;
$ui-button-focus-background-color: lighten($bright-pink-deep, 8%) !default;
$ui-button-focus-outline-color: $bright-pink !default;

$ui-button-tertiary-color: $princeton-orange !default;
$ui-button-tertiary-border-color: $princeton-orange;
$ui-button-tertiary-focus-background-color: $princeton-orange !default;
$ui-button-tertiary-focus-color: $white !default;

$gold-star: $princeton-orange;

$dark-text-color: $lilac-dark;

$font-display: 'cathode-code-pro-lc' !default;
