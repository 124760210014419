$emojis-requiring-inversion: 'back' 'copyright' 'curly_loop' 'currency_exchange'
  'end' 'heavy_check_mark' 'heavy_division_sign' 'heavy_dollar_sign'
  'heavy_minus_sign' 'heavy_multiplication_x' 'heavy_plus_sign' 'on'
  'registered' 'soon' 'spider' 'telephone_receiver' 'tm' 'top' 'wavy_dash' !default;

%emoji-color-inversion {
  filter: invert(1);
}

.emojione {
  @each $emoji in $emojis-requiring-inversion {
    &[title=':#{$emoji}:'] {
      @extend %emoji-color-inversion;
    }
  }
}

.hicolor-privacy-icons {
  .status__visibility-icon.icon-globe,
  .privacy-dropdown__option .icon-globe {
    color: #1976d2;
  }

  .status__visibility-icon.icon-unlock,
  .privacy-dropdown__option .icon-unlock {
    color: #388e3c;
  }

  .status__visibility-icon.icon-lock,
  .privacy-dropdown__option .icon-lock {
    color: #ffa000;
  }

  .status__visibility-icon.icon-envelope,
  .privacy-dropdown__option .icon-envelope {
    color: #d32f2f;
  }
}
