@use 'sass:color';

// Use Weld's background!
body {
  background-image: url(/public/background-cathode.png);
  background-attachment: fixed;
  background-repeat: repeat;
  background-size: 960px 960px; // Tile at native resolution
}


// What if the drawer background was transparent?
.drawer__inner {
  background-color: transparent;
}

// Position cathode logo in composing drawer
.drawer__inner__mastodon {
  background: transparent;
  flex: 1;
  min-height: 47px;
  padding: 0 25% 20px 25%;
  display: none;

  > img {
    object-position: bottom center;
    width: 100%;
  }

  @media screen and (min-height: 640px) {
    display: block;
  }
}

// Put a background for search results tho
.drawer__inner.darker {
  background-color: $ui-base-color;
}

nav a.brand {
    padding: 10px 15px !important;

    > svg.logo--wordmark {
        height: 27px !important;
        bottom: -1px !important;
    }
}

// Fix transparent columns on 4.3.0 alpha redesign

.column-header, .column .scrollable, .column .error-column, .drawer__header {
  background-color: $ui-base-color;
}

.notification__filter-bar, .account__section-headline, .column-header__collapsible {
  background-color: $russian-violet-darker;
}

// remove weird black bar at top of simple web interface
.tabs-bar__wrapper {
  background: none;
  backdrop-filter: none;
}

// Compose Form

// Stupid gargron borders
.compose-form .spoiler-input__border {
  background: none !important;
}

.compose-form .spoiler-input {
  background-color: $russian-violet-darker !important;
}
.compose-form .spoiler-input .autosuggest-input {
  border-bottom-width: 2px;
}

.compose-form__highlightable  {
  background: $russian-violet-darker !important;
}

.compose-form .autosuggest-textarea__textarea {
  color: $primary-text-color;
  background: inherit;
}

.compose-form .spoiler-input__input {
  //color: $secondary-text-color;
  color: $primary-text-color;
  background: inherit;
}

.compose-form__buttons button.icon-button, .compose-form__dropdowns button {
  color: $ui-secondary-color;
  border-color: $ui-secondary-color;
}

.drawer__pager {
  border: none;
  border-radius: none;
  background: transparent;
}

.drawer__inner .compose-form {
  border: 1px solid var(--background-border-color);
  border-radius: 4px;
  background: $ui-base-color;
}

// Admin/settings page

.admin-wrapper .sidebar-wrapper__inner {
  background: none;
}
.admin-wrapper .sidebar-wrapper__inner .sidebar {
  background-color: $russian-violet-dark;
}

.admin-wrapper .content-wrapper {
  padding: 20px;
  background-color: $russian-violet-darker;
}

// Fix the colour of the Wordmark text. screw you gargron...

#logo-symbol-wordmark .wordmark-text-cathode {
  fill:#f75ae2;
  stroke:none;
}

#logo-symbol-wordmark .wordmark-text-church {
  fill:#d22abc;
  stroke:none;
}

// Notification bar button has hard-coded colour which is stupid
.notification-bar-action {
  color: $bright-pink;
}