.no-list {
  list-style: none;

  li {
    display: inline-block;
    margin: 0 5px;
  }
}

.recovery-codes {
  list-style: none;
  margin: 0 auto;

  li {
    font-size: 125%;
    line-height: 1.5;
    letter-spacing: 1px;
  }
}
