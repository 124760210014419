@font-face {
  font-family: 'cathode-code-pro-lc';
  src: local('Code Pro LC'),
    url('~fonts/Code-Pro-LC/font.woff2') format('woff2'),
    url('~fonts/Code-Pro-LC/font.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/*@font-face {
  font-family: 'mastodon-font-display';
  src: local('Montserrat Medium'),
    url('../fonts/montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}*/
